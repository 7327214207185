import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { StorageKeys } from '@app/core/auth/models/storage.interface';
import { Token } from '@app/core/auth/models/token.interface';
import { AlertService } from '@app/core/auth/services/alert-service.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocalStorageService } from '@app/core/auth/services/local-storage.service';
import { TokenService } from '@app/core/auth/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, Observable, of, take } from 'rxjs';

export const homeLoginResolver: ResolveFn<
  AuthStatus | Observable<AuthStatus>
> = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const tokenService: TokenService = inject(TokenService);
  const localStorageService: LocalStorageService = inject(LocalStorageService);
  const alertService: AlertService = inject(AlertService);
  const router: Router = inject(Router);
  const translation = inject(TranslateService);

  if (route.queryParams && route.queryParams['code']) {
    return authService.loginUaePassToken(route.queryParams['code']).pipe(
      take(1),
      map((res: any) => {
        // Check if the user is registered
        if (!res.isRegistered) {
          // TODO: return it after finishing
          authService.setUserData(res);
          // setTimeout(() => {
          let userData = authService.getUserData();
          router.navigate(['auth/register']);
          // }, 1000)
          return of({ isAuthenticatedUser: false });
        } else if (res.accessToken) {
          authService.isAuthenticatedSubject.next(true);
          let token: Token = res;
          tokenService.authenticate(token);

          // Handle redirection after successful login
          if (localStorageService.get(StorageKeys.redirectionURL)) {
            router.navigate([
              localStorageService.get(StorageKeys.redirectionURL),
            ]);
            localStorageService.remove(StorageKeys.redirectionURL);
          }

          alertService.alertSucceess(
            translation.instant('general.messages.successLogin'),
          );
          return { isAuthenticatedUser: true };
        } else {
          router.navigate(['/']);
          return { isAuthenticatedUser: false };
        }
        //return of({ isAuthenticatedUser: true });
      }),
      catchError((error: any) => {
        router.navigate(['/']);
        return of({ isAuthenticatedUser: false });
      }),
    );
  } else if (route.queryParams && route.queryParams['error']) {
    return authService.logoutUaePass().pipe(
      map((res: any) => {
        if (
          route.queryParams['error'] !== 'cancelledOnApp' ||
          'invalid_request' ||
          'access_denied' ||
          'login_required'
        ) {
          window.location.href = res?.url;
        }
        authService.logOut();

        if (
          route.queryParams['error'] === 'cancelledOnApp' ||
          'invalid_request' ||
          'access_denied' ||
          'login_required'
        ) {
          alertService.alertError(
            translation.instant('general.errors.wrong'),
            translation.instant('general.messages.successLogout'),
          );
        }

        return { isAuthenticatedUser: false };
      }),
    );
  } else {
    if (authService.isAuthorized()) {
      return { isAuthenticatedUser: true };
    } else {
      return { isAuthenticatedUser: false };
    }
  }
};

export type AuthStatus = {
  isAuthenticatedUser: boolean;
};
