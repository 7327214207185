import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { LangConfig } from '../configs/lang.config';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from './localStorage-service';
import { StorageKeys } from '../configs/storage.config';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/enviroment';

export function ApplicationInitializerFactory(
  injector: Injector,
): () => Promise<void> {
  return () => ApplicationContainer(injector);
}

async function ApplicationContainer(injector: Injector): Promise<void> {
  return initializeTranslation(injector);
}

// initalize Translation and preload it
async function initializeTranslation(injector: Injector): Promise<void> {
  if (!environment.production) {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const defaultLang = LangConfig.AR;
    const translate: TranslateService = injector.get(TranslateService);
    translate.addLangs([LangConfig.EN, LangConfig.AR]);
    const localstorage = injector.get(LocalStorage);
    translate.setDefaultLang(
      localstorage.get(StorageKeys.language)
        ? localstorage.get(StorageKeys.language)
        : defaultLang,
    );
    try {
      await lastValueFrom(
        translate.use(
          localstorage.get(StorageKeys.language)
            ? localstorage.get(StorageKeys.language)
            : defaultLang,
        ),
      );
    } catch (err) {}
  } else {
    const defaultLang = LangConfig.AR;
    const translate: TranslateService = injector.get(TranslateService);
    const localstorage = injector.get(LocalStorage);
    translate.setDefaultLang(
      localstorage.get(StorageKeys.language)
        ? localstorage.get(StorageKeys.language)
        : defaultLang,
    );
  }
}
