import { LangConfig } from '../../../../configs/lang.config';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BaseComponent } from '@app/shared/base-component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
})
export class UserComponent extends BaseComponent implements OnInit {
  authorize: boolean = false;
  authService = inject(AuthService);
  router = inject(Router);
  inEditMode: boolean = false;
  onEdit: boolean = false;
  hasEdit: boolean = false;
  constructor() {
    super();
  }
  //enums
  LangConfig = LangConfig;

  ngOnInit(): void {
    this.authService.isAuthenticatedSubject.subscribe((a) => {
      this.authService.onEditSubject.subscribe((editFlag) => {
        if (editFlag) {
          this.inEditMode = true;
        } else {
          this.inEditMode = false;
        }
      });
      this.authService.hasEditSubject.subscribe((hasEdit) => {
        if (hasEdit) {
          this.hasEdit = true;
        } else {
          this.hasEdit = false;
        }
      });
    });
    this.authorize = this.authService.isAuthorized();
    this.getToken();
  }

  logOut() {
    this.authService.logoutUaePass().subscribe((res: any) => {
      this.authService.logOut();
      this.authorize = false;
      window.open(res?.url?.split('auth/sign-in')[0], '_self');
    });
  }

  protected override translatedContent(): void {}

  getToken() {
    const hasToken = localStorage.getItem('can_edit');
    return !!hasToken;
  }
}
