<section id="footer" [class.grey-bg]="isDarkBg">
  <div class="container">
    <div class="container-x">
      @if (loading) {
        <app-footer-skeleton></app-footer-skeleton>
      } @else {
        <div
          class="upper-footer d-flex justify-content-between align-items-center"
        >
          <div class="content-wrap">
            <div class="logo-text-wrapper">
              <img
                class="footer-logo"
                src="../../../../../../assets/img/footer-imgs/footer-logo.png"
                alt="jawaher-logo"
              />
              <h3 class="footer-slogan">
                {{ "general.footer.head" | translate }}
              </h3>
            </div>
          </div>
          <a [routerLink]="['/auth/sign-up']" class="register-now btn">
            {{ "general.signNow" | translate }}
          </a>
        </div>

        <div class="footer-details">
          <div class="contact-us">
            <h4 class="contact-heading">
              {{ "general.contactNow" | translate }}
            </h4>
            <div class="d-flex justify-content-between gap-5">
              <ul class="p-0">
                <li class="list-group-item">
                  {{ "general.footer.contact.lineOne" | translate }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.lineTwo" | translate }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.lineThree" | translate }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.lineFour" | translate }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.lineFive" | translate }}
                </li>
              </ul>
              <ul class="p-0">
                <li class="list-group-item">
                  {{
                    "general.footer.contact.DigitalTransformation" | translate
                  }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.lineThree" | translate }}
                </li>
                <li class="list-group-item">
                  {{ "general.footer.contact.dTLineOne" | translate }}
                </li>
              </ul>
            </div>
          </div>

          <div class="links">
            <h4 class="links-heading">
              {{ "general.footer.quickLinks" | translate }}
            </h4>
            <ul class="p-0">
              @for (nav of navs; track $index) {
                <li class="list-group-item">
                  <a
                    class="nav-link p-0"
                    aria-current="page"
                    (click)="nav.name == 'services' ? goToComponent() : ''"
                    [routerLink]="[nav?.link]"
                    routerLinkActive="router-link-active"
                    >{{ "general.header." + nav?.name | translate }}</a
                  >
                </li>
              }
            </ul>
          </div>

          <div class="Categories-wrap">
            <h4 class="categories">
              {{ "general.footer.categories.head" | translate }}
            </h4>
            <ul class="p-0">
              @for (navEntity of navsEntities; track $index) {
                <li class="list-group-item">
                  <button
                    class="nav-link p-0"
                    aria-current="page"
                    (click)="onclickRoute(navEntity?.link, navEntity?.param)"
                    routerLinkActive="router-link-active"
                  >
                    {{ "general.header." + navEntity?.name | translate }}</button
                  >
                </li>
              }

              <!-- <li class="list-group-item">{{"general.footer.categories.lineOne" | translate}}</li>
            <li class="list-group-item">{{"general.footer.categories.lineTwo" | translate}}</li>
            <li class="list-group-item">{{"general.footer.categories.lineThree" | translate}}</li>
            <li class="list-group-item">{{"general.footer.categories.lineFour" | translate}}</li> -->
            </ul>
          </div>
        </div>
      }
    </div>
    <div
      class="d-flex justify-content-center justify-content-lg-between flex-wrap py-3"
    >
      <div class="copy-right-content-policy">
        <h3 class="copyright-text">
          {{ "general.footer.printRights" | translate }}
        </h3>
        <h3 class="copyright-text">{{ "general.footer.terms" | translate }}</h3>
        <h3 class="copyright-text">
          {{ "general.footer.privacy" | translate }}
        </h3>
      </div>
      <div class="saved-rights-wrapper">
        <h3 class="saved-rights">
          © 2024 HHEO. {{ "general.footer.allRights" | translate }}
        </h3>
      </div>
    </div>
  </div>
</section>
