import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@app/core/auth/services/api-request.service';
import { LangConfig } from '@app/core/configs/lang.config';
import { TranslateKeys } from '@app/core/services/translation/translation-modules-enum';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

@Injectable()
export class ServerTranslationsService {
  token: string | null = null;
  actions: BehaviorSubject<{ loading: string | null; actions: string[] ; done?:string | null }> =
    new BehaviorSubject<{ loading: string | null; actions: string[] }>({
      loading: null,
      actions: [],
    });
  constructor(
    private apiService: ApiRequestService,
    private translationService: TranslateService,
  ) {}
  loginToUtility(): Observable<string> {
    this.actions.next({loading : "Logging In To Identity ...." , actions : []})
    const user = {
      userName: 'superadmin@dev.com',
      password: 'P@ssw0rd123456',
    };
    return this.apiService.post('Identity/api/Identity/Login', user).pipe(
      map((res: any) => {
        this.token = res.accessToken;
        this.actions.next({loading : "" , actions : ['Logged In Successfully']})
        return res.accessToken;
      }),
    );
  }

  getAndSetTranslations(language: LangConfig) {
    this.actions.next({loading : `Getting ${language} From Local Files ....` , actions : [...this.actions.value.actions]})
    return this.translationService.getTranslation(language).pipe(
      map((res: any) => {
        const translationsArray: { key: string; translation: any }[] = [];
        for (const key in TranslateKeys) {
          const obj: { key: string; translation: any } = {
            key: key,
            translation: { [TranslateKeys[key]]: res[TranslateKeys[key]] },
          };
          translationsArray.push(obj);
        }
        this.actions.next({loading : "" , actions : [...this.actions.value.actions , `Getting ${language} From Local Files Successfully`]})
        return translationsArray;
      }),
      switchMap((res) => {
        const requests = res.map((item) => {
          let headers = new HttpHeaders();
          headers = headers.set('Authorization', `Bearer ${this.token}`);

          return this.apiService.post(
            `Utilities/api/CMS/Resource/SetDocument?module=${item.key}&isArabic=${language == LangConfig.AR ? true : false}`,
            item.translation,
            headers,
          );
        });
        this.actions.next({loading : `Setting ${language} To Server .....` , actions : [...this.actions.value.actions]})
        return forkJoin(requests);
      }),
    );
  }

  startFlow(): Observable<any> {
    return this.loginToUtility().pipe(
      switchMap((res) => {
        return this.getAndSetTranslations(LangConfig.AR).pipe(map(res=>{
          this.actions.next({loading : `` , actions : [...this.actions.value.actions , `Setting AR To Server Successfully`]})
          return res
        }))
      }),
      switchMap((res) => {
        return this.getAndSetTranslations(LangConfig.EN).pipe(map(res=>{
          this.actions.next({loading : `` , actions : [...this.actions.value.actions , `Setting EN To Server Successfully`]})
          return res
        }))
      }),map(res=>{
        this.actions.next({loading : `` , actions : [...this.actions.value.actions] , done : "DONE"})
      })
    );
  }
}
