import { CommonModule } from '@angular/common';
import { afterRender, Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { Navs } from './nav.const';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SkeletonModule } from 'primeng/skeleton';
@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslateModule, ButtonModule, SkeletonModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
})
export class NavComponent implements OnInit{
  navs = Navs;
  router = inject(Router);
  translation = inject(TranslateModule);
  authService = inject(AuthService);
  loading: boolean = false;

  ngOnInit(): void {
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  }
  goToComponent() {
    window.location.href = window.location.origin+"#services"
  }
  onclickRoute(route: string, param?: any): void {
    this.router.navigate([route],{queryParams:{ param: param}});
  }
}
