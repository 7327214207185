import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-footer-skeleton',
  standalone: true,
  imports: [SkeletonModule],
  templateUrl: './footer-skeleton.component.html',
  styleUrl: './footer-skeleton.component.scss'
})
export class FooterSkeletonComponent {

}
