<div class="w-100 d-flex">
  @if(loading){
    <p-skeleton width="80px" height="80px" styleClass="mb-2" />
  } @else {
    <img
    alt="jawaher-logo"
    width="66px"
    height="80.76px"
    src="../../../../../../assets/img/logo.png"
    class="logo"
  />
  }
  <div class="d-flex align-items-center w-100">
    <nav class="navbar navbar-expand-lg w-100">
      <div class="container-fluid">
        <!-- Hamburger button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Fullscreen Menu -->
        <div class="collapse navbar-collapse fullscreen-menu" id="navbarSupportedContent">
          <!-- Close Button -->
          <button class="close-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
            ✖
          </button>

          <ul class="navbar-nav justify-content-md-start gap-4 mb-2 m-lg-0 p-0 w-100 mt-4 align-items-center flex-wrap">
            <li class="nav-item" *ngFor="let nav of navs" [class]="{ dropdown: nav?.hasChildren }">
              @if (loading) {
              <p-skeleton height="3rem" width="8rem" styleClass="mb-3" borderRadius="1.5rem" />
              } @else {
              <a class="nav-link p-0" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                [routerLink]="[nav?.link]" *ngIf="!nav?.hasChildren" [fragment]="nav?.toBlock ? 'services' : null" >
                {{ "general.header." + nav?.name | translate }}
              </a>

              <a class="nav-link dropdown-toggle" *ngIf="nav?.hasChildren" id="navbarDropdown1" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{ "general.header." + nav?.name | translate }}
              </a>

              <ul class="dropdown-menu" *ngIf="nav?.hasChildren">
                <li *ngFor="let menu of nav?.subMenus" class="submenu">
                  <button class="btn dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                    [routerLink]="menu?.link" [queryParams]="{ param: menu?.param }">
                    {{ "general.header." + menu?.name | translate }}
                  </button>
                </li>
              </ul>
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
