<div
  class="slider-skeleon-conainer d-flex justify-content-center align-items-center flex-wrap"
>
    <div class="d-flex w-100 flex-md-row flex-column justify-content-md-start justify-content-center align-items-center gap-4 gap-md-0">
        <p-skeleton width="4rem" height="5rem" />
        <p-skeleton height="3rem" width="31.25rem" class="mx-md-4" />
        <p-skeleton height="3rem" width="5rem" borderRadius="11px" class="ms-md-auto" />
    </div>

    <div class="d-flex w-100 flex-md-row flex-column justify-content-md-start justify-content-center align-items-center mt-5 gap-4 gap-md-0">
        <div class="d-flex w-100 flex-column justify-content-md-start justify-content-center align-items-md-start align-items-center">
            <p-skeleton width="5rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
        </div>
        <div class="d-flex w-100 flex-column justify-content-md-start justify-content-center align-items-md-start align-items-center">
            <p-skeleton width="5rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
        </div>
        <div class="d-flex w-100 flex-column justify-content-md-start justify-content-center align-items-md-start align-items-center">
            <p-skeleton width="5rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
            <p-skeleton width="15rem" height="2rem" borderRadius="11px" styleClass="mb-2" />
        </div>
    </div>

</div>
